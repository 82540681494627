.buttonLogin {
  display: block;
  color: var(--color-primary-100);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  white-space: nowrap;
}

.congressLogin {
  color: var(--color-bright-blue-2);
}

.studentsLogin {
  color: var(--color-base-black);
  font-weight: 500;
}

.buttonReg {
  display: none;
  min-width: 140px;
}

.profileControls {
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  cursor: pointer;
}

.profileIcon {
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  background-color: var(--color-orange-bg-2);
  border-radius: 50%;
  color: var(--color-primary-white);
  font-size: 20px;
  pointer-events: none;
  text-transform: uppercase;
}

.profileAdmin {
  filter: drop-shadow(0 0 4px #2abaff);
}

.noText {
  color: transparent;
}

.skeleton {
  width: 74px;
}

.medcoinWrap {
  display: none;
}

@media (min-width: 768px) {
  .buttonLogin {
    margin-right: 20px;
  }

  .buttonReg {
    display: block;
  }

  .studentsLogin {
    margin-right: 40px;
  }

  .medcoinWrap {
    display: block;
  }
}

@media (min-width: 1280px) {
  .profileIcon {
    width: 44px;
    height: 44px;
    font-size: 20px;
    pointer-events: all;
  }

  .skeleton {
    width: 130px;
  }
}